<template>
  <right-sidebar :display="display" @close="handleClose" title="Send payment" @handleBack="handlePreviousStep" :showBack="nextActiveStep !== 'beneficiary'">
    <template #body>
      <div class="payment-link-container-body relative">
        <p class="text-grey-2 text-sm" v-if="nextActiveStep === 'one' || nextActiveStep == 'two'">Fill beneficiary’s information</p>
        <!-- <keep-alive> -->
        <FirstStep :prefillbeneficiaryDetails="prefillbeneficiaryDetails" @resetBeneficiaryPreview="prefillbeneficiaryDetails = false" :wireAccountData="wireAccountData" @nextStep="handleNextStep" v-if="nextActiveStep === 'one'" :userData="userData"/>
        <second-step :wireAccountData="wireAccountData" @nextStep="handleNextStep" v-if="nextActiveStep === 'two'" :userData="userData"/>
        <Summary :wireAccountData="wireAccountData" v-if="nextActiveStep === 'summary'" :userData="userData" @success="handleClose"/>
        <Beneficiary :wireAccountData="wireAccountData" v-if="nextActiveStep === 'beneficiary'" @beneficiary="handleBeneficiary"/>
      <!-- </keep-alive> -->
      <beneficiary-preview @edit="handleEdit" v-if="nextActiveStep === 'preview'" :userData="userData" @nextStep="handleNextStep({step: 'two'})"/>

        <!-- <ReviewRate v-if="nextActiveStep === 'reviewRate'" @success="handleClose"/> -->
      </div>
    </template>
  </right-sidebar>
</template>
<script>
  import { RightSidebar } from "@/UI/Modals";
  import { Input, Select, CurrencyInput } from "@/UI/Input";
  export default {
    components: {
      RightSidebar,
      Input,
      Select,
      CurrencyInput,
      FirstStep: () => import("./FirstStep"),
      SecondStep:() => import('./SecondStep.vue'),
      Summary:() => import('./Summary'),
      Beneficiary:() => import('./Beneficiary.vue'),
      BeneficiaryPreview:() => import('./BeneficiaryPreview.vue')
      // ReviewRate:() => import('./ReviewRate')
    },
    props: {
      display: {
        type: Boolean,
        required: false,
        value: false,
      },
      externalBeneficiary:{
        type: Object,
        default(){
          return {}
        },
        required: false
      },
      wireAccountData:{
        type: Object,
        default:() => {}
      }
    },
    data:() => ({
        nextActiveStep: 'beneficiary',
        userData:{},
        beneficiaryData:{},
        paymentNavigation:[],
        prefillbeneficiaryDetails: false
    }),
    methods:{
        handleNextStep(value){
          this.nextActiveStep = value.step
            if(value.data){
              if(value.step === 'two'){
                this.userData.beneficiary = value.data
              }else{
                this.userData = {...this.userData, ...value.data.beneficiary, ...value.data.scheduleData}
              }
            }
            this.handleNavigation(value.step)
          // }
        },
        handleBeneficiary(data){
          if(data === 'new'){
            // this.nextActiveStep = 'one'
            this.prefillbeneficiaryDetails = false
            this.handleNextStep({step: 'one'})
          }else{
         
            this.$nextTick(() => {
              this.beneficiaryData = data;
              this.userData.beneficiary = data
              if((data.countrySpecificData.collectIfcs && !data.financialSystemCode) 
              || (data.countrySpecificData.collectBusinessRegistrationNumber && (!data.businessRegistrationNumber || isNaN(data.businessRegistrationNumber) || data.businessRegistrationNumber.length != 10))
              || (data.countrySpecificData.collectIban && data.accountNumber.length < 15)
              || (!data.phoneNumber)
            ){
              this.prefillbeneficiaryDetails = true
              this.handleNextStep({step: 'one'})
              }else{
                this.handleNextStep({step: 'preview'})
              }
              this.$forceUpdate()
            })
          }
        },
        handlePreviousStep(){
          this.paymentNavigation.pop();
            const lastStep = this.paymentNavigation[this.paymentNavigation.length - 1];
            if(lastStep){
              if(lastStep == 'one'){
                this.prefillbeneficiaryDetails = true
              }
              this.nextActiveStep = lastStep
            }
        },
        handleClose(){
          this.$emit('close');
          this.$router.replace({
              ...this.$route.params,
              query:{}
          })
        },
        handleNavigation (value) {
          this.paymentNavigation.push(value);
          
        },
        handleEdit(){
          this.prefillbeneficiaryDetails = true;
          this.handleNextStep({step: 'one'})   
        },
    },
    mounted(){
      if(Object.keys(this.externalBeneficiary).length){
        this.userData.beneficiary = this.externalBeneficiary
        this.handleNextStep({step: 'two'}) 
      }else{
        this.handleNextStep({step: 'beneficiary'}) 
      }
      
    }
  };
</script>
<style scoped>
  .payment-link-container-body {
    padding: 16px 24px;
  }
</style>
